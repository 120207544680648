import React from 'react';
import { Link } from 'gatsby';
import { getShareImage } from '../helpers/react/utils';

export default ({ pageContext }) => {
    const { posts, indexed, siteUrl } = pageContext;

    return <>
        <h1 style={{color:'white'}}>Todos los artículos</h1>
        <table style={{width:'100%'}}>
            <thead>
                <tr>
                    <th style={{color:'white'}}>Título</th>
                    <th style={{color:'white'}}>Descripción</th>
                    <th style={{color:'white'}}>URL</th>
                    <th style={{color:'white'}}>Imagen</th>
                </tr>
            </thead>
            <tbody>    
            {posts.map( ({node}) =>
                <tr>
                    <td>{node.title.value}</td> 
                    <td>{node.description ? node.description.value : ''}</td>
                    <td>
                        <Link to={`/${node.title.slug}/`}>
                            {`${siteUrl}/${node.title.slug}/`}
                        </Link>
                    </td>
                    <td>
                        <img src={getShareImage(node, indexed, siteUrl)} />
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    </>;
}