const getVideoLink = str => {
    const regex = /\bhttps?:\/\/\S+/gi;
    return regex.exec(str)[0].replace('"', '');
}

const getShareImage = (node, indexed, url) => {
    let images = [];

    if (node.images)
        images = node.images.value.reduce((result, image) => {
            image = image.value.data.value;
            if (image.indexOf('storage') !== -1) {
                let filename = image.split('\\').pop().split('/').pop();
                let [key, extension] = filename.split('.');

                /*if (extension.toLowerCase() === 'gif' && indexed.gifs[key])
                    result.push(url + indexed.gifs[key]);

                else if (indexed.images[key]) {*/
                    let publicFilename = filename.replace(`.${extension}`, '.jpg');
                    result.push(`${url}/static/shares/${publicFilename}`);
                //}
            }
            return result;
        }, []);

    else if (node.videos)
        images = node.videos.value.reduce((result, video) => {
            let link;
            video = video.value.data.value;
            if (video.indexOf('iframe') !== -1)
                link = getVideoLink(video);

            else if (video.indexOf('http') !== -1)
                link = video;

            let code = link.split('/').pop();
            result.push(`${url}/static/shares/${code}.jpg`);

            return result;
        }, []);

    return images.length ? images[0] : '';
}

export { getShareImage };